<template>
  <b-row class="d-flex flex-row align-items-center justify-content-start w-100" no-gutters>
    <!-- Contact Title row  -->
    <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
      <b-col cols="auto">
        <b-icon @click="gobackFn" icon="arrow-left" class="mr-2 mt-1" :scale="1.5"></b-icon>
      </b-col>
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary" style="fontsize: 1.1rem">
          {{ this.subtitle }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3" no-gutters>
      <!-- search input column -->
      <b-col class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0" cols="12"
        md="4">
        <!-- table search input -->
        <b-input-group class="search-input-group">
          <b-form-input class="search-input border-0 bg-light" type="text" placeholder="Search by name"
            v-model="searchTable" @keyup.native.enter="searchFn"></b-form-input>
          <b-input-group-append>
            <b-row class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
              no-gutters>
              <template v-if="searchTable">
                <b-icon class="text-main-green mr-2" icon="x" size="lg" aria-label="Search" @click="close"></b-icon>
              </template>
              <b-icon class="text-main-green" icon="search" size="lg" aria-label="Search"></b-icon>
            </b-row>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="d-flex flex-column align-items-end justify-content-center mr-2 py-2 py-lg-0" cols="12" md="4">
        <b-button variant="main-green"
          class="d-flex flex-row align-items-center justify-content-center mx-4 mx-md-0 my-2 my-lg-0 px-4 py-2 rounded-lg add-question-btn border-0"
          @click="chooseFile" pill>
          <b-icon variant="white" icon="circle-fill" size="sm" animation="throb" font-scale="1.4"
                v-if="checkout_active"></b-icon>
          <span class="d-inline ml-2"  v-if="!checkout_active ">Upload a file</span>
          <input class="file-input" ref="fileInput" type="file" @input="onSelectFile" />
        </b-button>
      </b-col>
    </b-row>
    <!-- full row -->
    <b-row class="d-flex flex-row align-items-center justify-content-start w-100" no-gutters>
      <b-table ref="businessTable" :bordered="false" :borderless="true" :hover="true" selectable show-empty
        select-mode="single" class="table-element" thead-class="table-header font-secondary" tbody-class="table-body"
        :busy="isLoading" :fields="ItemHeaders" :items="FileItems" responsive>
        <!-- table busy state -->
        <template #table-busy>
          <div class="text-center text-muted my-3">
            <b-spinner class="align-middle"></b-spinner>
            <span class="text-left mx-1">Loading...</span>
          </div>
        </template>
        <!-- table empty state -->
        <template #empty="scope">
          <b-row class="d-flex flex-row align-items-center justfiy-content-center py-5" no-gutters>
            <h6 class="text-center mb-0 mx-auto my-5">
              {{ scope.emptyText }}
            </h6>
          </b-row>
        </template>
        <!-- business Name  -->
        <template #cell(userName)="data">
          <b-col class="d-flex flex-column align-items-start justify-content-center px-0">
            <span class="main-text">{{ data.value }}</span>
          </b-col>
        </template>
        <!-- business Name  -->
        <template #cell(format)="data">
          <b-col class="d-flex flex-column align-items-start justify-content-center px-0">
            <span class="main-text">{{ data.value }}</span>
          </b-col>
        </template>


        <!-- actions column -->
        <template #cell(actions)="data">
          <!-- data.item -->
          <b-row class="d-flex flex-row align-items-end justify-content-end w-100" no-gutters>

            <b-col cols="4">
              <b-button v-b-tooltip.hover.right title="Download File" class="shadow-none  border border-success edit-btn"
                pill @click="downloadFile(data.item.url)">
                <b-icon icon="download" aria-label="Download File" class="edit-icon"></b-icon>
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-button v-b-tooltip.hover.right title="Remove Item" class="shadow-none border border-danger delete-btn"
                pill @click="removeItem(data.item.id)">
                <b-icon icon="trash" aria-label="Remove Item" class="delete-icon"></b-icon>
              </b-button>
            </b-col>


          </b-row>
        </template>
      </b-table>

    </b-row>
  </b-row>
</template>
  
<script>
// @ is an alias to /src
// components
import {
  CreateFileUpload,
  GetAllFiles, DeleteUploadFile, SearchFiles,
  PdfUpload
} from "@/services/workpaper.service";

export default {
  name: "UploadFolder",

  props: {
    itemContent: {
      type: Object,
    },
    subtitle: {
      type: String,
      required: true,
    },
    subid: {
      type: String,
      required: true,
    },
    mainFolderID: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      role: null,
      userName: null,
      format: null,
      searchTable: null,
      isLoading: false,
      selectedFile: null,
      SelectedFileName: null,
      checkout_active: false,
      fileFormat :null,
      id: null,
      url: null,
      ItemHeaders: [
        {
          key: "userName",
          label: "Name",
          sortable: true,
        },
        {
          key: "format",
          label: "Format",
        },

        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
      FileItems: [

      ]
    };
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
  },

  async mounted() {
    await this.initFn();

  },
  methods: {
    async initFn() {
      try {
        let payload = {
          id: this.subid,

        };
        this.isLoading = true;
        let { data } = await GetAllFiles(payload);
        console.log(data);
        this.FileItems = data
        this.FileItems = data.map((x) => {
          return {
            id: x._id,
            userName: x.name,
            format: x.format,
            url: x.url
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
       
      }
    },

    async onSelectFile() {
      const fileInput = this.$refs.fileInput;
      if (fileInput.files.length > 0) {
        this.selectedFile = fileInput.files[0];
        this.SelectedFileName = this.selectedFile.name.replace(/\.(pdf|csv|xlsx)$/i, '');
        this.fileFormat = this.selectedFile.name.split('.').pop();
        console.log("File Format:", this.fileFormat);
      } else {
        this.selectedFile = null;
      }

      if (this.selectedFile) {
        this.checkout_active = true;
        try {
          let formData = new FormData();
          formData.append("file", this.selectedFile);
          let response = await PdfUpload(formData);

          this.selectedFile = null;
          this.url = response.data.pdf;

          if (response.status === 200) {

            try {

              let payload = {
                name: this.SelectedFileName,
                format: this.fileFormat,
                mainFolder_id: this.mainFolderID,
                subFolder_id: this.subid,
                url: this.url
              };
              this.isLoading = true;
              await CreateFileUpload(payload);
              this.initFn()
              this.activeTab = 0
              this.isLoading = false;
              this.checkout_active = false;
            } catch (error) {
              this.isLoading = false;
            }
          }
        } catch (error) {
          this.checkout_active = false;
          this.isLoading = false;
        }
      }
    },
    async removeItem(fileID) {
      try {
        let payload = {
          id: fileID,
        };
        this.isLoading = true;
        let { data } = await DeleteUploadFile(payload);
        console.log(data);
        this.initFn()
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
       
      }
    },

    async downloadFile(URL) {
      // const fileUrl = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

      console.log("ID", URL)
      try {

        window.open(URL);
      } catch (error) {
        console.log(error);

      }
    },
    gobackFn() {
      this.$emit("goBackFn");
    },
    async chooseFile() {
      console.log("called");
      this.$refs.fileInput.click();
    },

    async searchCustomers() {
      try {
        this.FileItems = [];
        this.isLoading = true;
        let { data } = await SearchFiles({ search: this.searchTable });

        this.FileItems = data.map((x) => {
          return {
            id: x._id,
            userName: x.name,
            format: x.format,
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    searchFn() {
      // this.searchCustomers(1);
      if (this.searchTable && this.searchTable.length > 0) {
        // call the init function to load the data based on the search query
        // pass 1 into the function to reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
        this.searchCustomers();
      } else {
        this.initFn();
      }
    },
    close() {
      this.searchTable = null;
      this.initFn();
    },
  },
};
</script>
  
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.add-question-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.file-input {
  display: none;
}

.search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item.active,
.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element>>>.table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element>>>.table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element>>>.table-body td {
  vertical-align: middle;
}

.table-element>>>.table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element>>>.table-body .main-text {
  font-size: 0.9rem;
}

.table-element>>>.table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element>>>.table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element>>>.table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element>>>.table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .more-btn>.more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element>>>.table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element>>>.table-body .more-btn:hover>.more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element>>>.table-body .edit-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .edit-btn>.edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element>>>.table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element>>>.table-body .edit-btn:hover>.edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element>>>.table-body .delete-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .delete-btn>.delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element>>>.table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element>>>.table-body .delete-btn:hover>.delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element>>>.table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .expand-btn>.expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element>>>.table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element>>>.table-body .expand-btn:hover>.expand-icon {
  color: var(--white);
}

.table-element>>>.table-body .b-table-details {
  background-color: #fff;
}

.table-element>>>.table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination>>>.custom-page-item button,
.table-pagination>>>.custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination>>>.custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination>>>.custom-page-item.active button,
.table-pagination>>>.custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination>>>.custom-page-item.disabled button,
.table-pagination>>>.custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.permission-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: var(--gray-750);
}

.access-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #222831;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>