<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-between w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Password
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row
      class="d-flex flex-row align-items-stretch justify-content-center w-100 pt-4"
      no-gutters
    >
      <b-col cols="4" class="align-self-stretch">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Old Password 
          <span class="icon-color">*</span>
        </span
        >
      </b-col>
      <b-col cols="8" class="align-self-stretch">
        <FormInputGroup
          id="oldpasswordasa2-group-input"
          v-model="oldPassword"
          :state="oldPasswordError.status"
          :invalidFeedback="`${oldPasswordError.message}`"
          placeholder="Minimum 6 characters"
          isRequired
          :whiteBG="false"
          :type="oldPasswordShow ? 'text' : 'password'"
          :autocomplete="false"
          isAppend
        >
          <template v-slot:append>
            <b-button
              class="py-0"
              variant="transparent"
              @click="oldPasswordShow = !oldPasswordShow"
            >
              <b-icon
                :icon="oldPasswordShow ? 'eye' : 'eye-slash'"
                aria-label="Help"
              ></b-icon>
            </b-button>
          </template>
        </FormInputGroup>
      </b-col>
    </b-row>
    <!-- full row input (New Password) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >New Password
          <span class="icon-color">*</span>
        </span
        >
      </b-col>
      <b-col cols="8">
        <FormInputGroup
          groupId="password-group"
          id="password-group-input"
          v-model="newPassword"
          :state="newPasswordError.status"
          :invalidFeedback="`${newPasswordError.message}`"
          placeholder="Minimum 6 characters"
          isRequired
          :whiteBG="false"
          :type="newPasswordShow ? 'text' : 'password'"
          isAppend
          :autocomplete="false"
        >
          <template v-slot:append>
            <b-button
              class="py-0"
              variant="transparent"
              @click="newPasswordShow = !newPasswordShow"
            >
              <b-icon
                :icon="newPasswordShow ? 'eye' : 'eye-slash'"
                aria-label="Help"
              ></b-icon>
            </b-button>
          </template>
        </FormInputGroup>
      </b-col>
    </b-row>
    <!-- full row input (Confirm Password) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Confirm Password
          <span class="icon-color">*</span>
        </span
        >
      </b-col>
      <b-col cols="8">
        <FormInputGroup
          groupId="rrpassword-group"
          id="rrpassword-group-input"
          v-model="confirmNewPassword"
          :state="confirmNewPasswordError.status"
          :invalidFeedback="`${confirmNewPasswordError.message}`"
          placeholder="Minimum 6 characters"
          isRequired
          :whiteBG="false"
          :type="confirmNewPasswordShow ? 'text' : 'password'"
          isAppend
        >
          <template v-slot:append>
            <b-button
              class="py-0"
              variant="transparent"
              @click="confirmNewPasswordShow = !confirmNewPasswordShow"
            >
              <b-icon
                :icon="confirmNewPasswordShow ? 'eye' : 'eye-slash'"
                aria-label="Help"
              ></b-icon>
            </b-button>
          </template>
        </FormInputGroup>
      </b-col>
    </b-row>
    <!-- form action buttons  -->
    <b-row
      class="d-flex flex-row align-items-center justify-content-end justify-content-sm-end my-4 w-100"
    >
      <!-- forgot password button/link -->
        <b-link
         to="/forgot-password"
         class="font-primary font-weight-normal text-colour"
        >Forgot Password
        </b-link>
      <FormButton
        variant="main-green"
        isPill
        class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
        type="submit"
        :isLoading="isLoading"
        @click.native="resetPwdFn"
        ><span class="text-white">Change Password</span>
      </FormButton>
    </b-row>
  </b-row>
</template>

<script>
// @ is an alias to /src
// import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";

// services
import { ChangePassword } from "@/services/settings.service";

export default {
  name: "UserPasswordTab",
  components: {
    // FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isReset: false,
      isLoading: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
      oldPasswordError: {
        status: null,
        message: "",
      },
      newPasswordError: {
        status: null,
        message: "",
      },
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    oldPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.oldPasswordError.status = false;
            this.oldPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.oldPasswordError.status = true;
          }
        } else {
          this.oldPasswordError.status = false;
          this.oldPasswordError.message = "Please fill in your old password";
        }
      }
    },
    newPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.newPasswordError.status = false;
            this.newPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.newPasswordError.status = true;
          }
        } else {
          this.newPasswordError.status = false;
          this.newPasswordError.message = "Please fill in your new password";
        }
      }
    },
    confirmNewPassword(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.confirmNewPasswordError.status = false;
            this.confirmNewPasswordError.message =
              "A minimum length of 6 characters needed";
          } else {
            if (val == this.newPassword) {
              this.confirmNewPasswordError.status = true;
            } else {
              this.confirmNewPasswordError.status = false;
              this.confirmNewPasswordError.message =
                "Password should match the above one";
            }
          }
        } else {
          this.confirmNewPasswordError.status = false;
          this.confirmNewPasswordError.message =
            "Please fill in your new password again";
        }
      }
    },
  },
  async mounted() {
    // await this.initFn();
    // this.isEditProduct = this.$route.meta.isEdit;
    // if (this.isEditProduct) {
    //   this.initEditProductFn(this.$route.params.productId);
    // console.log(this.$route.params.invoiceId);
    // }
  },
  methods: {
    async resetPwdFn() {
      if (
        this.oldPassword &&
        this.newPassword &&
        this.confirmNewPassword &&
        this.newPassword == this.confirmNewPassword
      ) {
        let payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        this.isLoading = true;
        try {
          let { data, status } = await ChangePassword(payload);
          console.log("data", data);
          if (status == 200) {
            // this.$store.dispatch("register/setVendor", res.data.content);
            let payloadNotify = {
              isToast: true,
              title: "Password Change",
              content: "Your password has been changed successfully",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.resetFn();
          } else {
            // this.isLoader = false;
            // this.loginError.status = true;
            // this.loginError.message = data.details;
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Password Reset",
              content: data.details,
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Password Reset",
            //content: error.message,
            content: "Your old password is incorrect",
            variant: "danger",
          };

          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errorFn();
      }
    },
    errorFn() {
      if (!this.oldPassword) {
        this.oldPasswordError.status = false;
        this.oldPasswordError.message = "Please fill in your old password";
      }
      if (!this.newPassword) {
        this.newPasswordError.status = false;
        this.newPasswordError.message = "Please fill in your new password";
      }
      if (!this.confirmNewPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message =
          "Please fill in your new password again";
      }
      if (this.confirmNewPassword != this.newPassword) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message =
          "Password should match the above one";
      }
    },
    resetFn() {
      this.isReset = true;
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;

      setTimeout(() => {
        this.oldPasswordError.status = null;
        this.newPasswordError.status = null;
        this.confirmNewPasswordError.status = null;
      }, 600);
    },
  },
};
</script>

<style scoped>
.text-colour{
  color: #0B9B7D;
  margin-right: 20px;
}

.icon-color{
  color: red;
}

.btn-rounded {
  border-radius: 0 10px 10px 0;
}
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
