<template>
  <b-row class="d-flex flex-row align-items-center justify-content-between w-100" no-gutters>
    <!-- Contact Title row  -->
    <template v-if="!isInvite">
      <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
        <b-col class="d-flex flex-column align-items-start justify-content-start">
          <h5 class="text-left text-dark font-weight-bold font-primary">
            Folder List
          </h5>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-start w-100 px-0 mx-0 my-4" no-gutters>
        <div class="container ">

          <b-tabs vertical v-model="activeTab" @input="handleTabChange" active-nav-item-class="font-weight-bold text-dark"
            class="custom-tabs">

            <div no-body v-for="(tab, index) in tabs" :key="index">

              <div class="tab-title">
                <b-row class="d-flex flex-row align-items-center justify-content-between" style="height: 75px;">
                  <b-col class="d-flex align-items-start justify-content-start" cols="2">
                    <b-button title="Folder" class="shadow-none Folder-btn" variant="link"
                      @click="UploadFolder(tab.title, tab.id)">
                      <b-icon icon="folder2-open" aria-label="Folder" class="Folder-icon"></b-icon>
                    </b-button>
                  </b-col>
                  <b-col class="d-flex flex-column align-items-start justify-content-start"
                    @click="UploadFolder(tab.title, tab.id)">
                    <b-row><span class="cursor-pointer green-title  subtopic-text"
                        @click="UploadFolder(tab.title, tab.id)">{{ tab.title }}</span></b-row>
                    <b-row><span v-if="tab.isDefault == true" style="font-size: 14px;">default</span></b-row>
                    <b-row v-if="tab.CreatedBy"><span v-if="tab.isDefault == false" style="font-size: 14px;">Created By:
                        {{ tab.CreatedBy }}</span></b-row>
                    <b-row v-if="tab.Status"><span v-if="tab.isDefault == false" style="font-size: 14px;">Status: {{
                      tab.Status }}</span></b-row>
                  </b-col>
                  <b-col v-if="tab.isDefault == false" class="d-flex align-items-end justify-content-end ">

                    <b-button title="Edit Item" variant="link" class="shadow-none delete-btn" @click="EditTab(tab.id)"
                      :no-focus="true">
                      <b-icon icon="pencil-square" aria-label="Edit Item" class="edit-icon"></b-icon>
                    </b-button>

                    <b-button title="Remove Item" class="shadow-none delete-btn" variant="link"
                      @click="removeTab(tab.id)">
                      <b-icon icon="trash" aria-label="Remove Item" class="delete-icon"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
              </div>


            </div>

            <hr class="table-line" />
            <b-row class="d-flex flex-row align-items-center justify-content-center">
              <div class="add-tab">
                <b-button @click="addNewTab" variant="primary" pill>Add sub folder</b-button>
              </div>
            </b-row>




          </b-tabs>
          <b-modal id="modal-center" size="md" centered v-model="show" @hide="closePopup" title="SubFolder Name">
            <template #modal-header-close>
              <!-- Emulate built in modal header close button action -->
              <!-- <h6>Are you sure you want to delete this customer?</h6> -->
              <b-icon icon="x-circle" class="text-dark" @click="closePopup" aria-label="Close Modal"
                font-scale="0.8"></b-icon>
            </template>
            <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
              <b-col>
                <FormInput groupId="code-group" id="code-group-input" class="text-prime-gray rounded-2"
                  label="Folder Name" v-model="subfoldername" :whiteBG="false" form="login-form"></FormInput>
              </b-col>
            </b-row>
            <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
              <b-col>
                <FormInput groupId="code-group" id="code-group-input" class="text-prime-gray rounded-2" label="Created By"
                  v-model="CreatedBy" :whiteBG="false" form="login-form"></FormInput>
              </b-col>
            </b-row>
            <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
              <b-col>
                <FormSelect groupId="type-group" id="type-group-input" class="text-prime-gray rounded-2" label="Status"
                  v-model="Status" :whiteBG="false" :options="statusOptions" form="login-form" placeholder="Default">
                </FormSelect>
              </b-col>
            </b-row>
            <template #modal-footer="{}">
              <b-row flex-row align-items-center justify-content-center>
                <b-col d-flex flex-row align-items-center justify-content-center align-self="center"
                  style="padding-right: 0px">
                  <FormButton isPill class="font-weight-normal text-secondary mt-2 login-button"
                    style="background-color: #bdbdbd; border: none" @click.native="closePopup"><span
                      class="text-white">Cancel</span></FormButton>
                </b-col>
                <b-col d-flex flex-row align-items-center justify-content-center align-self="center">
                  <FormButton isPill class="font-weight-normal text-secondary mt-2 login-button"
                    @click.native="ActionBtnFn" style="background-color: #0b9b7d; border: none"><span
                      class="text-white">Save</span></FormButton>
                </b-col>
              </b-row>
            </template>
          </b-modal>
        </div>
      </b-row>
    </template>
    <template v-else>
      <b-row class="d-flex flex-row align-items-center justify-content-start" no-gutters>
        <UploadFolder @goBackFn="callgoBackFn" :subtitle="this.title" :subid="this.id"
          :mainFolderID="this.mainFolderID" />
      </b-row>


    </template>
  </b-row>
</template>
<script>

import UploadFolder from "@/components/WorkPaperFolder/UploadFolder";
import { GetAllSubFolders, CreateSubFolder, DeleteSubFolder, GetSubFolderDetails, UpdateSubFolder } from "@/services/workpaper.service";
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "SubFolder",
  props: {
    mainFolderID: {
      default: null,
    },
  },
  data() {
    return {
      isInvite: false,
      show: false,
      activeTab: 0,
      isLoading: false,
      title: null,
      isDefault: false,
      id: null,
      CreatedBy: null,
      subfoldername: null,
      Status: null,
      statusOptions: ["Completed", "WIP", "Not Required"],
      tabs: [
        {
          title: "",
          isDefault: false,
          id: "",
          CreatedBy: "",
          Status: ""
        }
      ],
    };
  },
  components: {
    FormButton,
    FormInput,
    UploadFolder,
    FormSelect
  },
  watch: {
    mainFolderID: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.initFn();
        }
      },
    },
  },
  // async mounted() {
  //     await this.initFn();
  //     // await this.loadCurrency();

  //   },
  methods: {
    UploadFolder(subTitle, subID) {

      this.title = subTitle
      this.id = subID
      this.isInvite = true;
      console.log("create", subTitle);
    },

    async initFn() {
      try {
        let payload = {
          id: this.mainFolderID,

        };
        console.log("MainFolderID", this.mainFolderID);
        this.isLoading = true;
        let { data } = await GetAllSubFolders(payload);
        console.log(data);

        this.tabs = data
        this.tabs = data.map((x) => {
          return {
            title: x.name,
            isDefault: x.isDefault,
            id: x._id,
            CreatedBy: x.createdBy,
            Status: x.status
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error('Error fetching main folders:', error);
      }
    },

    callgoBackFn() {
      this.isInvite = false;
    },
    handleTabChange(newTab) {
      this.activeTab = newTab;
    },
    addNewTab() {

      this.show = true;
      console.log('clicked');

    },
    async ActionBtnFn() {
      if (this.isEdit) {
        let payload = {
          id: this.subID,
          name:this.subfoldername,
          createdBy: this.CreatedBy,
          status: this.Status
        };
        try {
          this.isLoading = true;
          await UpdateSubFolder(payload);
          this.initFn()
          this.activeTab = 0
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
        this.show = false;
        this.isEdit = false;
      } else {

        let payload = {
          name: this.subfoldername,
          mainFolder: this.mainFolderID,
          createdBy: this.CreatedBy,
          status: this.Status
        };
        try {

          this.isLoading = true;
          let { data } = await CreateSubFolder(payload);
          console.log("New Folder Created", data);
          this.initFn()

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.error('Error fetching main folders:', error);
        }
        this.show = false;
        this.subfoldername = null
        this.CreatedBy = null
        this.Status = null
        // this.initFn();
      }
    },
    async EditTab(subID) {
      this.subID = subID;
      this.isEdit = true;
      this.activeTab = 0
      this.show = true;
      let payload = {
          id: this.subID,

        };
      try {

        this.isLoading = true;
        let { data } = await GetSubFolderDetails(payload,{ id: this.mainFolderID });
        this.subfoldername = data.name,
          this.CreatedBy = data.createdBy,
          this.Status = data.status,
          this.initFn()
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async removeTab(subID) {
      try {
        let payload = {
          id: subID,

        };
        console.log("SubID", subID);
        this.isLoading = true;
        let { data } = await DeleteSubFolder(payload);
        console.log(data);
        this.initFn()
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error('Error fetching main folders:', error);
      }
    },
    closePopup() {
      this.show = false;
     
      this.subfoldername = null
      this.CreatedBy = null
      this.Status = null
    },
  },
};
</script>
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item.active,
.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

.invoice-tabs {
  /* width: 100%; */
  font-size: 1rem;
  display: grid;
  grid-template-columns: 100%;
  /* overflow: hidden; */
  /* background-color: var(--ghost-white); */
}

.subtopic-text {
  font-family: "Lato";
  font-style: bold;
  font-weight: 600px;
  font-size: 16px;
  line-height: 15px;
  text-align: left;
  /* color: var(--main-green); */
  margin-bottom: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.tab-title {
  justify-content: space-between;
  height: 70px;
  border: 1px solid #0B9B7D;
  border-radius: 20px;
  margin-bottom: 10px;
}

.custom-tabs {
  width: 100%;

}

hr.table-line {
  border: 1px solid #e0e0e0;
}

.add-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  Width: 168px;
  Height: 42px;
}

.delete-btn {
  width: 40px;
  height: 40px;
  background-color: transparent;

  box-shadow: none;
  outline: none;
  border: none;

}

.delete-btn>.delete-icon {
  color: var(--danger);

}

.delete-btn>.edit-icon {
  color: #0B9B7D;

}

.Folder-btn {

  background-color: transparent;
  border: none;

}

.Folder-btn>.Folder-icon {
  width: 60px;
  height: 40px;
  color: #0B9B7D;


}

.action-btn {
  height: 40px;
  min-width: 80px;
  border-width: 2px;
}

.login-button {
  border-radius: 30px;
  width: 142px;
  height: 30px;
  background: #0b9b7d;
}

.action-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
  font-weight: normal;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .action-btn {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .action-btn {
    width: auto;
  }
}
</style>
  