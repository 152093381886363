<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-between w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Dates & Currency
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pt-4"
      no-gutters
    >
      <b-col cols="12" sm="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Fiscal Year</span
        >
      </b-col>
      <b-col cols="12" sm="8" class="mt-3 mt-sm-0">
        <FormSelect
          groupId="fiscalyear-group"
          id="fiscalyear-group-input"
          class="text-prime-gray rounded-2"
          :state="fiscalyearError.status"
          :invalidFeedback="`${fiscalyearError.message}`"
          label="Fiscal year"
          v-model="fiscalyear"
          :whiteBG="false"
          :options="fiscalyearOptions"
          form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (New Password) -->
    <b-row
      class="d-flex align-items-start justify-content-center w-100"
      no-gutters
    >
      <b-col cols="12" sm="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Currency</span
        >
      </b-col>
      <b-col cols="12" sm="8" class="mt-2 mt-sm-0">
<!--        <FormInput-->
<!--          groupId="business-country-group"-->
<!--          id="business-country-group-input"-->
<!--          class="text-prime-gray rounded-2"-->
<!--          v-model="currency"-->
<!--          isRequired-->
<!--          :whiteBG="false"-->
<!--          trim-->
<!--          :isDisabled="false"-->
<!--          form="login-form"-->
<!--          type="text"-->
<!--        ></FormInput>-->
        <FormSelect
            groupId="industry-group"
            id="industry-group-input"
            class="text-prime-gray rounded-2 mt-2"
            label="Choose a currency"
            :state="currencyError.status"
            :invalidFeedback="`${currencyError.message}`"
            v-model="currency"
            :whiteBG="false"
            :options="currencyList"
            form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- form action buttons  -->
    <b-col
      class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end"
    >
      <b-button
        variant="main-green"
        pill
        class="px-5 py-1 py-sm-2 border-0 add-question-btn"
        @click="updateFiscalFn"
        :disabled="getUserRole.role.includes('User')"
      >
        <span class="d-inline px-sm-3">Save</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src
// import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";

// services
import {
  GetCurrentWorkspace,
  UpdateCurrentWorkspace,
} from "@/services/settingsbusiness.service";
import {mapGetters} from "vuex";

export default {
  name: "DatesAndCurrency",
  components: {
    // FormInput,
    FormSelect,
  },
  data() {
    return {
      currency: "NZD",
      currencyList:["NZD", "FJD", "AUD", "USD"],
      fiscalyear: null,
      fiscalyearOptions: ["January - December", "April - March","July - June"],
      fiscalyearError: {
        status: null,
        message: "",
      },
      currencyError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    fiscalyear(value) {
      if (value) {
        this.fiscalyearError.status = true;
      } else {
        this.fiscalyearError.status = false;
        this.fiscalyearError.message = "Please fill out this field";
      }
    },
  },
  async mounted() {
    await this.initFn();
  },
  methods: {
    async initFn() {
      let { data } = await GetCurrentWorkspace();
      console.log("GetWorkspace data", data);
      this.fiscalyear = data.fiscal_year;
      this.currency = data.currency;
    },
    async updateFiscalFn() {
      if (this.fiscalyear) {
        let payload = {
          fiscal_year: this.fiscalyear,
          currency: this.currency
        };
        try {
          let { data, status } = await UpdateCurrentWorkspace(payload);
          console.log("fiscal updated", data);
          if (status == 200) {
            let payloadNotify = {
              isToast: true,
              title: "Dates & Currency Update",
              content: "The fiscal year has been updated successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.add-question-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
