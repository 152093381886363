<template>
  <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
    <!-- Payment Connection -->
    <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
      <b-col col="12">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Receive Online Payments
        </h5>
        <p class="mt-4">
          Click the button to connect with your Stripe account for payments.
        </p>
        <b-col col="12" class="pb-2 mt-4">
          <b-button v-if="!isLoading && !accountStatus" variant="main-green"
            class="stripe-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg" pill
            @click="connectStripe" :disabled="isLoading || getUserRole.role.includes('User')">
            <span class="d-inline">Connect Stripe</span>
          </b-button>
          <b-button v-if="!isLoading && accountStatus" variant="main-green"
            class="stripe-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg" pill
            @click="getAccountLink" :disabled="isLoading || getUserRole.role.includes('User')">
            <span class="d-inline">Login to Stripe</span>
          </b-button>
          <div v-if="isLoading">
            <b-spinner class="spinner" variant="success" type="grow" label="Spinning"></b-spinner>
          </div>

          <div class="mt-3 mb-4">
            <span class="small-font-size border border-dark p-1">Powered By <strong>Stripe</strong></span>
          </div>
          <b-alert show variant="danger" v-if="accountStatus && stripeOnboardComplete === false"><a href="#"
              class="alert-link">Please complete your Stripe Account onboarding process.</a></b-alert>
          <b-alert show variant="danger" v-if="accountStatus && stripeChargesEnabled === false"><a href="#"
              class="alert-link">Your Stripe Account is not eligible to receive payments due to
              incomplete information.</a></b-alert>
        </b-col>
      </b-col>
    </b-row>
    <hr class="bg-light w-100" />
    <!-- Package Information -->
    <b-row class="d-flex flex-row align-items-center justify-content-center w-100" no-gutters>
      <b-col col="12">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Package Subscriptions
        </h5>
      </b-col>
      <b-row class="mt-4 d-flex flex-row align-items-center justify-content-start w-100" no-gutters>
        <b-col col="3" md="2" lg="2" class="mr-4">
          <p>Package type :</p>
          <p>Package price :</p>
          <p>Billing :</p>
          <p>Start date :</p>
        </b-col>
        <b-col col="3" lg="2" md="2">
          <p>{{ pacKName }}</p>
          <p>{{ `$${price}` }}</p>
          <p>{{ is_annual ? "Annually" : "Monthly" }}</p>
          <p>{{ startedDate }}</p>
        </b-col>
      </b-row>
      <b-col col="12">
        <b-col col="12" class="pb-2 mt-4">
          <b-button variant="main-green"
            class="stripe-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg" pill
           
            @click="changePackage"
             :disabled="getUserRole.role.includes('User')">
            <span class="d-inline">Change Package</span>
          </b-button>
        </b-col>
        <b-col cols="12" md="12" class="mt-2">
          <h6 class="mb-xl-4 d-xl-inline p-0" style="color: #0b9b7d; font-weight: bold; font-weight: normal">
            Want to stop payment and deactivate your account?
            <a style="cursor: pointer; color: #0b9b7d; font-weight: bold"
              :disabled="getUserRole.role.includes('User')">Click here</a>
          </h6>
        </b-col>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import { GetPackageDetails } from "@/services/settingsbusiness.service";
import {
  createStripAccount,
  checkStripAccountStatus,
  getAccountLink,
} from "@/services/stripe.service";
import { mapGetters } from "vuex";

export default {
  name: "PaymentsTab",
  data() {
    return {
      stripeOnboardComplete: false,
      stripeChargesEnabled: false,
      accountId: null,
      accountStatus: true,
      pacKName: null,
      startedDate: null,
      price: null,
      isLoading: false,
      is_annual: false,
      trialEnd: true
    };
  },
  async mounted() {
    const res = await GetPackageDetails();
    this.getAccountStatus();
    this.price = parseFloat(res.data.packageDetails.price).toFixed(2);
    this.pacKName = res.data.packageDetails.name;
    this.is_annual = res.data.packageDetails.is_annual_renew;
    this.startedDate = this.formatDate(res.data.packageDetails.created_at);
    this.trialEnd = res.data.packageDetails.is_trial;
  },
  methods: {
    async getAccountStatus() {
      const { data } = await checkStripAccountStatus();
      console.log(
        "🚀 ~ file: PaymentsTab.vue:165 ~ getAccountStatus ~ data:",
        data
      );
      this.accountStatus = data.status;
      if (data.accountId) {
        this.accountId = data.accountId;
        this.stripeOnboardComplete = data.stripeOnboardComplete;
        this.stripeChargesEnabled = data.stripeChargesEnabled;
      }
    },
    async connectStripe() {
      try {
        this.isLoading = true;
        const { data } = await createStripAccount();
        if (data.status === 500 || data.status === 502) {
          this.isLoading = false;
          let payloadNotify = {
            isToast: true,
            title: "Connect Stripe",
            content: "Something went wrong.Please try agin letter",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          return;
        }
        window.location.href = data.returnLink;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async getAccountLink() {
      try {
        this.isLoading = true;
        const { data } = await getAccountLink(this.accountId);
        window.location.href = data.accountLink;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    changePackage() {
      if (this.trialEnd == false) {
        this.$router.push({
          path: '/packages',
          query: {
            lockedAccount: true
          },
        });
      } else {
        this.$router.push({
          path: '/packages',

        });
      }

    },
    formatDate(date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
      const day = dateObject.getDate();
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    },
    formatNumber(num) {
      // Convert input string to a number and store as a variable.
      var value = Number(num);

      // Split the input string into two arrays containing integers/decimals
      var res = num.toString().split(".");
      // If there is no decimal point or only one decimal place found.
      if (res.length == 1 || res[1].length < 3) {
        // Set the number to two decimal places
        value = value.toFixed(2);
      }
      return value;
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
    }),
  },
};
</script>

<style scoped>
.small-font-size {
  font-size: 0.7rem;
  margin-left: 21%;
}

.stripe-btn {
  padding: 10px 30px 10px 30px;
  margin-left: 18%;
  height: 50px;
  width: 180px;
}

.spinner {
  margin-left: 22%;
}
</style>
