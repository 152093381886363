<template>
  <b-table
    ref="businessTable"
    :bordered="false"
    :borderless="true"
    :hover="true"
    selectable
    show-empty
    select-mode="single"
    class="table-element"
    thead-class="table-header font-secondary"
    tbody-class="table-body"
    :busy="isLoading"
    :fields="businessHeader"
    :items="businessItems"
  >
    <!-- table busy state -->
    <template #table-busy>
      <div class="text-center text-muted my-3">
        <b-spinner class="align-middle"></b-spinner>
        <span class="text-left mx-1">Loading...</span>
      </div>
    </template>
    <!-- table empty state -->
    <template #empty="scope">
      <b-row
        class="d-flex flex-row align-items-center justfiy-content-center py-5"
        no-gutters
      >
        <h6 class="text-center mb-0 mx-auto my-5">
          {{ scope.emptyText }}
        </h6>
      </b-row>
    </template>
    <!-- business Name  -->
    <template #cell(businessName)="data">
      <b-col
        class="d-flex flex-column align-items-start justify-content-center px-0"
      >
        <span class="main-text">{{ data.value }}</span>
        <span class="text-muted sub-text">{{
          data.value.productDescription
        }}</span>
      </b-col>
    </template>
    <!-- role -->
    <template #cell(role)="data">
      <span class="main-text mb-2">{{ data.value }}</span>
    </template>
    <!-- actions column -->
    <template #cell(actions)="data">
      <b-button
        class="border-0 align-items-center justify-content-center defult-btn"
        disabled
        v-if="data.value.isdefault"
      >
        Default
      </b-button>

      <b-link
        v-else
        @click="makeDefaultFn(data.value.business_id)"
        class="text-left font-info font-weight-bold load-more-btn mr-2"
        :disabled="getUserRole.role.includes('User')"
        >Make Default
      </b-link>
      <!-- edit account button -->
      <b-button
        v-b-tooltip.hover.left
        title="Edit Business"
        class="shadow-none mx-1 border-0 edit-btn"
        pill
        @click="editBusinessFn(data.value.business_id)"
        :disabled="getUserRole.role.includes('User')"
      >
        <b-icon
          icon="pencil-square"
          aria-label="Edit Business"
          class="edit-icon"
        ></b-icon>
      </b-button>
    </template>
  </b-table>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
// services
import { setDefaultBussiness } from "@/services/settings.service";

export default {
  name: "BusinessList",
  components: {},
  props: {
    itemContent: {
      type: Array,
    },
    isLoadingProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      isDefault: false,
      isCreate: false,
      isLoading: null,
      isEditPage: false,
      businessHeader: [
        {
          key: "businessName",
          label: "Name",
          sortable: true,
        },
        {
          key: "role",
          label: "Role",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
      businessItems: [],
    };
  },
  computed: {
    ...mapGetters({
      getIsActiveTab: "profileSettings/getIsActiveTab",
      getUserRole: "auth/getUserRole"
    }),
  },
  watch: {
    itemContent(val) {
      console.log("userBusinesses watch", val);
      // let isdefault = false;
      this.businessItems = val.map((x) => {
        this.isDefault = false;
        if (x.is_default === x.business_id) {
          this.isDefault = true;
        }
        return {
          businessName: x.business_name,
          role: x.role.charAt(0).toUpperCase() + x.role.slice(1),
          // isDefault:x.is_default,
          // actions: x.business_id,
          actions: {
            business_id: x.business_id,
            isdefault: this.isDefault,
          },
        };
      });
    },
    // getIsActiveTab(val) {
    //   if (val) {
    //     this.$store.dispatch("profileSettings/setIsCreateBusiness", true);
    //   }
    // }
    // isLoadingProp(val) {
    //   this.isLoading = val;
    //   console.log("isLoading", val)
    // }
  },
  async mounted() {
    // if (this.getIsActiveTab == true) {
    //   this.$store.dispatch("profileSettings/setIsCreateBusiness", true);
    // }
    // await this.initFn();
    // console.log("from store userBusinesses", this.userBusinesses);
    // this.businessItems = this.userBusinesses.map((x) => {
    //   return {
    //     businessName: x.business_name,
    //     role: x.role.charAt(0).toUpperCase() + x.role.slice(1),
    //     actions: x.business_id,
    //   };
    // });
  },
  methods: {
    // async initFn() {
    //   try {
    //     let { data, status } = await GetUserProfile();
    //     if (status == 200) {
    //       this.businessItems = data.member_of.map((x) => {
    //         return {
    //           businessName: x.business_name,
    //           role: x.role.charAt(0).toUpperCase() + x.role.slice(1),
    //           actions: x.business_id,
    //         };
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    editBusinessFn(id) {
      // this.$router.push("/products/edit/" + id);
      this.isEditPage = true;
      console.log("business id", id);
      this.$store.dispatch("profileSettings/setBusinessId", id);
      this.$store.dispatch("profileSettings/setIsCreateBusiness", true);
    },
    makeDefaultFn(id) {
      let payload = {
        business_id: id,
      };
      console.log("payload", payload);
      setDefaultBussiness(payload)
        .then((res) => {
          if (res.status == 200) {
            let payloadNotify = {
              isToast: true,
              title: "Bussiness Update",
              content: "The bussiness has been updated to default",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            console.log("notify", payloadNotify);
            console.log("business id", payload);
            this.isDefault = true;
            console.log(this.isDefault);
            this.$emit('update:itemContent', this.itemContent + ' Updated');
            this.$router.push('/');
          } else
            this.handleErrorMakeDefault(
              "Could not update bussiness to default"
            );

        })

        .catch(() =>
          this.handleErrorMakeDefault("Could not update bussiness to default")
        );
    },
    handleErrorMakeDefault(err) {
      let payloadNotify = {
        isToast: true,
        title: "Bussiness Update",
        content: err,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
      console.log("notify", payloadNotify);
    },

  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-element .load-more-btn {
  transition: color 200ms ease-in;
  color: var(--main-green);
  font-size: 0.9em;
}

.table-element .load-more-btn:hover {
  color: var(--main-green);
  text-decoration: none;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  /* min-width: 1000px; */
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* default button */
.defult-btn {
  background-color: #219653;
  background: rgba(33, 150, 83, 0.5);
  color: black;
  font-weight: 600;
  border-radius: 30px;
  width: 106px;
  height: 30px;
  margin-right: 10px;
  padding-bottom: 30px;
}
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
