<template>
  <HomeLayout>
    <b-container class="d-flex flex-column align-items-start justify-content-start px-0">
      <!-- settings container row -->
      <b-row class="d-flex flex-row align-items-stretch justify-content-center settings-card-row w-100" no-gutters>
        <b-card no-body class="shadow-sm border-0 settings-container-card w-100">
          <b-row cols="12" class="d-flex flex-row align-items-start justify-content-start w-100 px-0 mx-0 my-4"
            no-gutters>


            <div class="container ">

              <b-tabs vertical v-model="activeTab" @input="handleTabChange"
                active-nav-item-class="font-weight-bold text-dark" class="invoice-tabs mx-0 w-100"
                nav-class="tab-wrapper w-100" nav-wrapper-class="custom-tabs-wrapper w-100 p-4" content-class="w-100 p-4">

                <b-tab no-body title-link-class="tab-nav-link text-main-green" v-for="(tab, index) in defaulttabs"
                  :key="index">
                  <template #title>
                    <div class="tab-title">
                      <b-row class="d-flex flex-row align-items-center justify-content-between">
                        <b-col class="d-flex flex-column align-items-start justify-content-start">
                          <!-- Updated classes -->
                          <b-row><span class="green-title subtopic-text">{{ tab.title }}</span></b-row>

                          <b-row v-if="tab.CreatedBy"><span v-if="tab.isDefault == false" style="font-size: 14px;">Created
                              By: {{ tab.CreatedBy }}</span></b-row>
                          <b-row v-if="tab.Status"><span v-if="tab.isDefault == false" style="font-size: 14px;">Status: {{
                            tab.Status }}</span></b-row>
                        </b-col>

                        <b-col v-if="tab.isDefault == false" class="d-flex align-items-end justify-content-end">
                          <!-- Updated classes -->

                          <b-button title="Edit Item" variant="link" class="shadow-none delete-btn"
                            @click="EditTab(tab.id)" :no-focus="true">
                            <b-icon icon="pencil-square" aria-label="Edit Item" class="edit-icon"></b-icon>
                          </b-button>

                          <b-button title="Remove Item" variant="link" class="shadow-none delete-btn"
                            @click="removeTab(tab.id)" :no-focus="true">
                            <b-icon icon="trash" aria-label="Remove Item" class="delete-icon"></b-icon>
                          </b-button>
                        </b-col>

                      </b-row>
                    </div>
                  </template>
                  <subFolder :mainFolderID="tab.id" />

                </b-tab>


                <b-tab title-link-class="tab-nav-link text-main-green" :title="''">
                  <template #title>
                    <hr class="table-line" />
                    <b-row class="d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
                      <div class="add-tab">
                        <!-- Button in the non-clickable tab -->
                        <b-button @click="addNewTab" variant="primary">
                          <b-icon class="mr-1" style="color: white;" icon="plus-circle" size="sm" aria-label="Plus"
                            font-scale="1.1"></b-icon>
                          Add Main Folder
                        </b-button>
                      </div>
                    </b-row>
                  </template>
                </b-tab>
              </b-tabs>


            </div>
            <!-- vertical divider (hidden on medium screens and below) -->
            <!-- <hr class="d-none d-lg-block h-100 border-left" /> -->
          </b-row>
        </b-card>
      </b-row>
      <b-modal id="modal-center" size="md" centered v-model="show" @hide="closePopup" title="Folder Name">
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to delete this customer?</h6> -->
          <b-icon icon="x-circle" class="text-dark" @click="closePopup" aria-label="Close Modal"
            font-scale="0.8"></b-icon>
        </template>
        <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
          <b-col>
            <FormInput groupId="code-group" id="code-group-input" class="text-prime-gray rounded-2" label="Folder Name"
              v-model="mainfoldername" :whiteBG="false" form="login-form"></FormInput>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
          <b-col>
            <FormInput groupId="code-group" id="code-group-input" class="text-prime-gray rounded-2" label="Created By"
              v-model="CreatedBy" :whiteBG="false" form="login-form"></FormInput>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3" no-gutters>
          <b-col>
            <FormSelect groupId="type-group" id="type-group-input" class="text-prime-gray rounded-2" label="Status"
              v-model="Status" :whiteBG="false" :options="statusOptions" form="login-form" placeholder="Default">
            </FormSelect>
          </b-col>
        </b-row>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col d-flex flex-row align-items-center justify-content-center align-self="center"
              style="padding-right: 0px">
              <FormButton isPill class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none" @click.native="closePopup"><span
                  class="text-white">Cancel</span></FormButton>
            </b-col>
            <b-col d-flex flex-row align-items-center justify-content-center align-self="center">
              <FormButton isPill class="font-weight-normal text-secondary mt-2 login-button" @click.native="ActionBtnFn"
                style="background-color: #0b9b7d; border: none"><span class="text-white">Save</span></FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import subFolder from "@/components/WorkPaperFolder/SubFolder";
import FormButton from "@/components/Form/FormButton";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import { GetAllMainFolders, CreateMainFolder, DeleteMainFolder, UpdateMainFolder, GetMainFolderDetails } from "@/services/workpaper.service";

export default {
  name: "Workpaper Folder",
  components: {
    HomeLayout,
    subFolder,
    FormButton,
    FormInput,
    FormSelect
  },
  data() {
    return {
      activeTab: 0,
      show: false,
      mainfoldername: null,
      isLoading: false,
      title: null,
      isDefault: false,
      CreatedBy: null,
      id: null,
      Status: null,
      isEdit: null,
      statusOptions: ["Completed", "WIP", "Not Required"],
      tabs: [],
      defaulttabs: [
        {
          title: "",
          isDefault: false,
          id: "",
          CreatedBy: "",
          Status: ""
        }
      ]
    };
  },
  async mounted() {
    await this.initFn();
  },
  created() {
    this.activeTab = 0;
  },
  methods: {
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetAllMainFolders();
        this.defaulttabs = data.folders
        this.defaulttabs = data.folders.map((x) => {
          return {
            title: x.name,
            isDefault: x.isDefault,
            id: x._id,
            CreatedBy: x.createdBy,
            Status: x.status

          };
        });
        this.activeTab = 0
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error('Error fetching main folders:', error);
      }
    },
    addNewTab() {
      this.activeTab = 0
      this.show = true;
    },
    closePopup() {
      this.show = false;
      this.activeTab = 0
      this.mainfoldername = null
      this.CreatedBy = null
      this.Status = null
    },
    async removeTab(mainID) {
      try {
        let payload = {
          id: mainID,
        };
        this.isLoading = true;
        await DeleteMainFolder(payload);
        this.initFn()
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async EditTab(mainID) {
      this.mainID = mainID
      this.isEdit = true;
      this.activeTab = 0
      this.show = true;
      try {

        this.isLoading = true;
        let { data } = await GetMainFolderDetails({ id: mainID });
        this.mainfoldername = data.name,
          this.CreatedBy = data.createdBy,
          this.Status = data.status,
          this.initFn()
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async ActionBtnFn() {
      if (this.isEdit) {
        let payload = {
          id: this.mainID,
          name: this.mainfoldername,
          createdBy: this.CreatedBy,
          status: this.Status
        };
        try {
          this.isLoading = true;
          await UpdateMainFolder(payload);
          this.initFn()
          this.activeTab = 0
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
        this.show = false;
        this.isEdit = false;
      } else {
        let payload = {
          name: this.mainfoldername,
          createdBy: this.CreatedBy,
          status: this.Status
        };
        try {
          this.isLoading = true;
          await CreateMainFolder(payload);
          this.mainfoldername = null
          this.CreatedBy = null
          this.Status = null
          this.initFn()
          this.activeTab = 0
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
        this.show = false;
      }

    },
  },

};
</script>

<style>
.custom-tabs-wrapper {
  border-bottom: 1px #dee2e6 solid;
}

@media (min-width: 1100px) {
  .custom-tabs-wrapper {
    border-bottom: none;
    border-right: 1px #dee2e6 solid;
  }
}
</style>
<style scoped>
/* table card */
.settings-card-row {
  height: 100%;
}

.settings-card-row .settings-container-card {
  background-color: var(--white);
  min-width: 100%;
  height: auto;
  /* height: 650px; */
  border-radius: 0;
  border: 1px solid var(--light);
}

.settings-card-row .settings-container-card>>>.tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
  /* padding-top: 40px; */
}

.settings-card-row .settings-container-card>>>.invoice-tabs {
  /* width: 100%; */
  font-size: 1rem;
  display: grid;
  grid-template-columns: 100%;
  /* overflow: hidden; */
  /* background-color: var(--ghost-white); */
}

.settings-card-row .settings-container-card>>>.invoice-tabs .tab-nav-link {
  font-size: 1rem;
  /* padding-right: 20px;
  padding-top: 20px; */
  /* padding: 10px auto; */
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--main-green);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.settings-card-row .settings-container-card>>>.invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--main-green);
}

.settings-card-row .settings-container-card>>>.invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  /* border-bottom: 3px solid var(--main-green); */
}

/* table actions row */
.settings-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.settings-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.settings-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.settings-card-row .filter-dropdown,
.settings-card-row .add-question-btn {
  height: 45px;
}

.settings-card-row .filter-dropdown>>>.filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.settings-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item.active,
.settings-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* table question action buttons */
/* edit icon button (default state) */
.table-container-row .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .edit-btn>.edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-container-row .edit-btn:hover {
  background-color: var(--main-green);
}

.table-container-row .edit-btn:hover>.edit-icon {
  color: var(--white);
}

.tab-title {

  justify-content: space-between;
  color: green;
}

.add-tab {
  display: flex;
  justify-content: start;
  align-items: start;
  Width: 168px;
  Height: 42px;
}

/* delete icon button (default state) */
.delete-btn {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;

}

.delete-btn>.delete-icon {
  color: var(--danger);

}

.delete-btn>.edit-icon {
  color: #0B9B7D;

}

/* delete icon button (hover state) */


/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.topic-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: var(--gray-750);
  margin-bottom: 25px;
}

.login-button {
  border-radius: 30px;
  width: 142px;
  height: 30px;
  background: #0b9b7d;
}

.subtopic-text {
  font-family: "Lato";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  /* color: var(--main-green); */
  margin-bottom: 5px;
}

hr.table-line {
  border: 1px solid #e0e0e0;
}

.non-clickable-tab {
  /* Add any additional styles to visually distinguish the non-clickable tab */
  pointer-events: none;
  opacity: 0.6;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

  .settings-card-row .filter-dropdown,
  .settings-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .insight-cards-row {
    height: auto;
  } */
}

@media (min-width: 1100px) {
  .settings-card-row .settings-container-card>>>.invoice-tabs {
    grid-template-columns: 25% 75%;
  }
}

/* @media (min-width: 576px) {
    .settings-card-row .settings-container-card>>>.invoice-tabs {
        grid-template-columns: 40% 60%;
    }
} */
</style>
