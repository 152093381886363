<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-start w-100"
    no-gutters
  >
    <b-alert show variant="danger" v-if="!isLimitExceed"
      >Your invite user limit has been exceeded. Try to buy more users.</b-alert
    >
    <!-- Heading and Invite -->
    <b-row
      class="d-flex flex-row align-items-center justify-content-center w-100 mb-4"
      no-gutters
      v-if="!iseditUser"
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Users : Personal Account
        </h5>
      </b-col>

      <b-button
        variant="main-green"
        class="invite-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg"
        pill
        @click="inviteUserFn()"
        :disabled="!isLimitExceed"
      >
        <span class="d-inline">Invite a User</span>
      </b-button>
    </b-row>
    <template v-if="!iseditUser">
      <b-row
        class="d-flex flex-row align-items-center justify-content-start w-100"
        no-gutters
      >
        <b-table
          ref="businessTable"
          :bordered="false"
          :borderless="true"
          :hover="true"
          selectable
          show-empty
          select-mode="single"
          class="table-element"
          thead-class="table-header font-secondary"
          tbody-class="table-body"
          :busy="isLoading"
          :fields="businessHeader"
          :items="businessItemsSample"
          responsive
        >
          <!-- table busy state -->
          <template #table-busy>
            <div class="text-center text-muted my-3">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- table empty state -->
          <template #empty="scope">
            <b-row
              class="d-flex flex-row align-items-center justfiy-content-center py-5"
              no-gutters
            >
              <h6 class="text-center mb-0 mx-auto my-5">
                {{ scope.emptyText }}
              </h6>
            </b-row>
          </template>
          <!-- business Name  -->
          <template #cell(userName)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text">{{ data.value }}</span>
            </b-col>
          </template>
          <!-- business Name  -->
          <template #cell(userEmail)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text">{{ data.value }}</span>
            </b-col>
          </template>
          <!-- role -->
          <template #cell(invited)="data">
            <span
              class="main-text mb-2"
              :class="data.value ? 'highlight' : ''"
              >{{ data.value }}</span
            >
          </template>
          <!-- role -->
          <template #cell(role)="data">
            <span class="main-text mb-2"
              >{{ data.value
              }}<span class="font-weight-bold">{{
                data.item.owner ? " (Owner)" : null
              }}</span></span
            >
          </template>
          <!-- role -->
          <template>
            <span class="main-text mb-2">Invite User</span>
          </template>
          <!-- actions column -->
          <template #cell(actions)="data">
            <!-- data.item -->
            <div class="d-flex align-items-center justify-content-start">
              <!-- edit account button -->
              <!-- @click="editUserFn(data.item.id)" -->
              <div v-if="data.item.role != 'Owner'">
                <b-button
                  id="show-btn"
                  v-b-tooltip.hover.left
                  title="Edit Account"
                  class="shadow-none mx-1 border-0 edit-btn"
                  pill
                  :disabled="
                    getUserRole.role.includes('User') || data.item.owner
                  "
                  @click="handleEdit(data.item)"
                >
                  <b-icon
                    icon="pencil-square"
                    aria-label="Edit Business"
                    class="edit-icon"
                    :disabled="getUserRole.role.includes('User')"
                  ></b-icon>
                </b-button>

                <!-- delete account button -->
                <b-button
                  v-b-tooltip.hover.left
                  title="Delete Account"
                  @click="openDeleteAccountModal(data.item, $event.target)"
                  class="shadow-none mx-1 border-0 delete-btn"
                  pill
                  :disabled="
                    getUserRole.role.includes('User') || data.item.owner
                  "
                >
                  <b-icon
                    icon="trash"
                    aria-label="Delete Account"
                    class="delete-icon"
                  ></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
        <b-row
          class="d-flex flex-row align-items-center justify-content-start mt-3"
          no-gutters
        >
          <b-col>
            <h5 class="text-left text-dark font-weight-bold font-primary mb-4">
              User Purchase
            </h5>
            <p class="mb-">Click the button below to purchase more users.</p>
            <b-button
              variant="main-green"
              class="invite-btn d-flex flex-row align-items-center justify-content-center border-0 rounded-lg"
              pill
              :disabled="getUserRole.role.includes('User') || isCheckoutLoading"
              @click="buyUser"
            >
              <div>
                <b-spinner v-if="isCheckoutLoading" small type="grow">
                </b-spinner>
                <span v-if="isCheckoutLoading"> Loading...</span>
                <span v-if="!isCheckoutLoading" class="d-inline"
                  >Buy More Users</span
                >
              </div>
            </b-button>
          </b-col>
        </b-row>
      </b-row>
      <DeleteAccountModal
        :isShow="isShowDeleteItemRow"
        @onClose="onCloseDeleteAccountModal"
        @onDelete="deleteInviteUser"
        :id="id"
        :key="showDeleteAccountModalKey"
      />
    </template>
    <template v-else>
      <b-row
        class="d-flex flex-row align-items-center justify-content-start"
        no-gutters
      >
        <InviteUser @goBackFn="callgoBackFn" />
      </b-row>
    </template>
    <b-modal
      id="bv-modal-edit"
      title="Update invite user information"
      centered
      hide-footer
    >
      <b-row cols="12" class="flex-column w-100" no-gutters>
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-bold"
            >Update User Name</span
          >
        </b-col>
        <b-col cols="9 mt-2">
          <FormInput
            groupId="business-name-group"
            id="business-email-group-input"
            class="text-prime-gray rounded-2"
            :state="userNameError.status"
            :invalidFeedback="`${userNameError.message}`"
            v-model="userName"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row cols="12" class="flex-column w-100" no-gutters>
        <b-col cols="4">
          <span class="text-left text-dark font-primary font-weight-bold"
            >Update Email Address</span
          >
        </b-col>
        <b-col cols="9 mt-2">
          <FormInput
            groupId="business-name-group"
            id="business-email-group-input"
            class="text-prime-gray rounded-2"
            :state="userEmailError.status"
            :invalidFeedback="`${userEmailError.message}`"
            v-model="userEmail"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        cols="12"
        class="d-flex flex-row align-items-center justify-content-start w-100"
        no-gutters
      >
        <span>Role: <span class="font-weight-bold">Accountant</span></span>
        <!-- <b-form-radio value="Accountant" class="ml-2">Accountant</b-form-radio> -->
      </b-row>
      <b-row
        cols="12"
        class="d-flex flex-row align-items-center justify-content-end w-100"
        no-gutters
      >
        <button class="btn btn-light mr-3" @click="handleCancel">Cancel</button>
        <button class="btn btn-primary" @click="handleUpdate">Update</button>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
// @ is an alias to /src
import FormInput from "@/components/Form/FormInput";
import { GetWorkspaceMembers } from "@/services/settingsbusiness.service";
import {
  updateInviteUser,
  purchaseOneUser,
} from "@/services/invitations.service";
import InviteUser from "@/components/Settings/Business/UserManagement/InviteUser";
import DeleteAccountModal from "@/components/Settings/Business/UserManagement/Modal/DeleteAccountModal.vue";
import { mapGetters } from "vuex";
import CheckEmail from "@/util/CheckEmail";

// services

export default {
  name: "UserList",
  components: {
    InviteUser,
    DeleteAccountModal,
    FormInput,
  },
  props: {
    itemContent: {
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      isCheckoutLoading: false,
      editedId: null,
      isLimitExceed: false,
      iseditUser: false,
      isCreate: false,
      showDeleteAccountModalKey: Number(new Date()) + 1100,
      isLoading: false,
      isEditing: false,
      isShowDeleteItemRow: false,
      rowItem: null,
      userEmail: null,
      userName: null,
      userEmailError: {
        status: null,
        message: "",
      },
      userNameError: {
        status: null,
        message: "",
      },
      businessItemsSample: [],
      businessHeader: [
        {
          key: "userName",
          label: "Name",
        },
        {
          key: "userEmail",
          label: "Email",
        },
        {
          key: "role",
          label: "Role",
        },
        {
          key: "Invited",
          label: "",
          class: "text-right",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  watch: {
    userName(value) {
      if (value) {
        this.userNameError.status = true;
      } else {
        this.userNameError.status = false;
        this.userNameError.message = "Please fill out this field";
      }
    },
    userEmail(value) {
      this.userEmailError.status = true;
      if (!value) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Please fill out this field";
      } else if (CheckEmail(value) == false) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Email is not valid";
      }
    },
  },
  async mounted() {
    this.initFn();
  },
  methods: {
    handleCancel() {
      this.$bvModal.hide("bv-modal-edit");
      this.reset();
    },
    handleEdit(data) {
      this.editedId = data.id;
      this.userEmail = data.userEmail;
      this.userName = data.userName;
      this.$bvModal.show("bv-modal-edit");
    },
    inviteUserFn() {
      this.iseditUser = true;
    },
    editUserFn(id) {
      console.log("editUserFn", id);
      // this.$router.push("/inviteuser/edit/" + id);
      this.iseditUser = true;
    },
    callgoBackFn() {
      this.iseditUser = false;
      this.initFn();
    },
    async buyUser() {
      try {
        this.isCheckoutLoading = true;
        const res = await purchaseOneUser();
        window.location.href = res.data;
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "Error!",
          content: error.response.data.msg,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isCheckoutLoading = false;
      }
    },
    async handleUpdate() {
      if (!this.userName || !this.userEmail) {
        let payloadNotify = {
          isToast: true,
          title: "Error!",
          content: "Please felid all the values",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return;
      }
      try {
        this.isEditing = true;
        const { data } = await updateInviteUser(this.editedId, {
          name: this.userName,
          email: this.userEmail,
        });
        this.initFn();
        this.$store.dispatch("auth/setEmail", this.userEmail);
        this.handleCancel();
        let payloadNotify = {
          isToast: true,
          title: "Update invite user information",
          content: data.msg,
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "Error!",
          content: error.response.data.msg,
          variant: "danger",
        };
        await this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isEditing = false;
      }
    },

    reset() {
      this.userEmail = null;
      this.userName = null;
      this.editedId = null;
    },

    async deleteInviteUser(id) {
      const email = this.$store.getters["auth/getEmail"];
      const deleteUser = this.businessItemsSample.find(
        (item) => item.id === id
      );
      if (email === deleteUser.userEmail) {
        let payloadNotify = {
          isToast: true,
          title: "Sign Out",
          content: "Your are not member of a Figabooks",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        this.$store.dispatch("auth/logOut");
        this.$router.push("/login");
        return;
      }
      this.initFn();
    },
    async initFn() {
      try {
        this.isLoading = true;
        const { data } = await GetWorkspaceMembers();
        this.isLimitExceed = data.data.limit;
        this.businessItemsSample = data?.data.list.map((x) => {
          return {
            id: x._id,
            userName: x.name,
            userEmail: x.email,
            role: x.role.charAt(0).toUpperCase() + x.role.slice(1),
            owner: x.owner,
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    openDeleteAccountModal(item, button) {
      this.id = item.id;
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowDeleteItemRow = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-account-row-modal", button);
    },
    onCloseDeleteAccountModal() {
      this.isShowDeleteItemRow = false;
      this.showDeleteAccountModalKey = Number(new Date()) + 1100;
    },
    callInitFn() {
      this.showDeleteAccountModalKey = Number(new Date()) + 1100;
      this.initFn();
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
      getModulePermissions: "auth/getModulePermissions",
      getActivePackage: "auth/getActivePackage",
    }),
  },
};
</script>
<style scoped>
.highlight {
  padding: 4px 15px 4px 15px;
  border-radius: 30px;
  background: #1c72ff33;
}

.delete-btn,
.edit-btn {
  cursor: pointer;
}

.edit-btn,
.delete-btn:disabled {
  cursor: not-allowed;
}
.invite-btn {
  padding: 10px 30px 10px 30px;
  margin-left: 30%;
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
/* .table-container-row .table-element {
  min-width: 1000px;
} */
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  /* background-color: var(--light); */
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  /* background-color: var(--main-green); */
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  /* color: var(--white); */
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  /* background-color: var(--danger); */
  /* cursor: pointer; */
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  /* color: var(--white); */
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.action-btn {
  height: 50px;
  min-width: 120px;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
  .action-btn {
    width: 80%;
  }
}
</style>
