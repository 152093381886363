<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-between w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Send a Feedback
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pt-4"
      no-gutters
    >
      <b-col cols="12" sm="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Email</span
        >
      </b-col>
      <b-col cols="12" sm="8" class="mt-2 mt-sm-0">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          :state="userEmailError.status"
          :invalidFeedback="`${userEmailError.message}`"
          label="Your Email"
          v-model="userEmail"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (New Password) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100"
      no-gutters
    >
      <b-col cols="12" sm="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Feedback</span
        >
      </b-col>
      <b-col cols="12" sm="8" class="mt-2 mt-sm-0">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          label="Your feedback"
          v-model="userFeedback"
          :state="userFeedbackError.status"
          :invalidFeedback="userFeedbackError.message"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
          isTextArea
        ></FormInput>
      </b-col>
    </b-row>
    <!-- form action buttons  -->
    <b-col
      class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end"
    >
      <b-button
        variant="main-green"
        pill
        class="px-5 py-2 border-0 action-btn"
        @click="sendFeedback"
        :disabled="getUserRole.role.includes('User')"
      >
        <span class="d-inline px-4">Send</span>
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// @ is an alias to /src
import FormInput from "@/components/Form/FormInput";
import { postFeedback } from "@/services/settings.service";

import CheckEmail from "@/util/CheckEmail";
import {mapGetters} from "vuex";

export default {
  name: "SendFeedback",
  components: {
    FormInput,
  },
  data() {
    return {
      userEmail: null,
      userFeedback: null,
      progressBTN: false,
      userEmailError: {
        status: null,
        message: "",
      },
      userFeedbackError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    userEmail(val) {
      if (val) {
        if (CheckEmail(val) == true) this.userEmailError.status = true;
        else {
          this.userEmailError.status = false;
          this.userEmailError.message = "Please fill in a valid email address";
        }
      } else {
        if (!this.userEmailError.status) {
          this.userEmailError.status = false;
          this.userEmailError.message = "Please fill in the email address";
        }
      }
    },
    userFeedback(val) {
      if (val) {
        this.userFeedbackError.status = true;
      } else {
        if (!this.userFeedbackError.status) {
          this.userFeedbackError.status = false;
          this.userFeedbackError.message = "Fill in the Feedback";
        }
      }
    },
  },
  async mounted() {},
  methods: {
    resetFn() {
      this.userFeedback = "";
      this.userEmail = "";
    },
    sendFeedback() {
      if (this.userFeedback && this.userEmail) {
        var payload = {
          feedback: this.userFeedback,
          email: this.userEmail,
        };
        postFeedback(payload)
          .then((res) => {
            let { data, status } = res;
            console.log(data);
            if (status == 200) {
              let payloadNotify = {
                isToast: true,
                title: "Feedback Message",
                content: "Your Feedback was sent successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.resetFn();
            } else {
              console.log("Server Error");
              let payloadNotify = {
                isToast: true,
                title: "Feedback Message",
                content: "Your Feedback was not send",
                list: ["Internal server error"],
                variant: "danger",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
          })
          .catch((error) => {
            console.log(error);
            let payloadNotify = {
              isToast: true,
              title: "Feedback Message",
              content: "Your Feedback was not send",
              list: [error.message],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          });
      } else {
        this.errorFn();
      }
    },
    errorFn() {
      if (!this.userEmail) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Please fill in the Email";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Send the Feedback",
          content: "Please enter the Email",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }

      if (!this.userFeedback) {
        this.userFeedbackError.status = false;
        this.userFeedbackError.message = "Please fill in the Feedback";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Send the Feedback",
          content: "Please enter the Feedback",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
  },
};
</script>
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.action-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.action-btn {
  height: 50px;
  min-width: 120px;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
  .action-btn {
    width: 80%;
  }
}
</style>
