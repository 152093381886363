<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-between w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Profile Information
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (Customer) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100 pt-4"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Full name</span
        >
      </b-col>
      <b-col cols="8">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          :state="fullNameError.status"
          :invalidFeedback="fullNameError.message"
          v-model="fullName"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Date Of Birth</span
        >
      </b-col>
      <b-col cols="8">
        <b-form-datepicker
          id="custom-start-date"
          v-model="dateofbirth"
          :state="dateofbirthError.status"
          :invalidFeedback="dateofbirthError.message"
          locale="en-UK"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          selected-variant="main-green"
          today-variant="main-green"
          class="rounded-1 bg-white shadow-none"
          menu-class="bg-white border border-main-green rounded-lg custom-calendar-dropdown"
        ></b-form-datepicker>
        <!-- <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          v-model="dateofbirth"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput> -->
      </b-col>
    </b-row>
    <!-- Billing Address Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start pt-4"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-gray-750 font-weight-normal font-primary">
          Address
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100 pt-4"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >No :</span
        >
      </b-col>
      <b-col cols="8">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressNum"
          :state="addressNumError.status"
          :invalidFeedback="addressNumError.message"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Street) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Street</span
        >
      </b-col>
      <b-col cols="8">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressStreet"
          :state="addressStreetError.status"
          :invalidFeedback="addressStreetError.message"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (City) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >City</span
        >
      </b-col>
      <b-col cols="8">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressCity"
          :state="addressCityError.status"
          :invalidFeedback="addressCityError.message"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="text"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Zip Code) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Zip Code</span
        >
      </b-col>
      <b-col cols="8">
        <FormInput
          groupId="user-name-group"
          id="user-name-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressZip"
          :state="addressZipError.status"
          :invalidFeedback="addressZipError.message"
          isRequired
          :whiteBG="false"
          trim
          form="login-form"
          type="number"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Province) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Country</span
        >
      </b-col>
      <b-col cols="8">
        <FormSelect
          groupId="fiscalyear-group"
          id="fiscalyear-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressCountry"
          :state="addressCountryError.status"
          :invalidFeedback="addressCountryError.message"
          :whiteBG="false"
          :options="countryList"
          form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Country) -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-end w-100 "
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Province</span
        >
      </b-col>
      <b-col cols="8">
        <FormSelect
          groupId="fiscalyear-group"
          id="fiscalyear-group-input"
          class="text-prime-gray rounded-2"
          v-model="addressProvince"
          :state="addressProvinceError.status"
          :invalidFeedback="addressProvinceError.message"
          :whiteBG="false"
          :options="filteredProvinceList"
          form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- form action buttons  -->
    <b-row
      class="d-flex flex-row align-items-center justify-content-end justify-content-sm-end my-4 w-100"
    >
      <b-button
        variant="main-green"
        pill
        class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
        @click="updateProfileFn"
        :disabled="getUserRole.role.includes('User')"
      >
        Save
      </b-button>
    </b-row>
  </b-row>
</template>

<script>
// @ is an alias to /src
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
// import FormSelect from "@/components/Form/FormSelect";

// services
import { UpdateUserProfile } from "@/services/settings.service";
import {mapGetters} from "vuex";

export default {
  name: "PersonalInfoTab",
  components: {
    FormInput,
    FormSelect,
    // FormSelect,
  },
  props: {
    itemContent: {
      type: Object,
    },
  },
  data() {
    return {
      fullName: null,
      dateofbirth: null,
      addressNum: null,
      addressStreet: null,
      addressCity: null,
      addressZip: null,
      addressProvince: null,
      addressCountry: null,
      countryList: ["New Zealand", "Australia","Fiji"],
      NewzelandProvinceList: [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke's Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland",
      ],
      AusiProvinceList: [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania",
      ],
      fijiTimeoneOption: ["Fiji Standard Time (GMT +12)"],
      fijiProvinceList:["Ba","Bua","Cakaudrove","Kadavu","Lau","Lomaiviti","Macuata","Nadroga-Navosa",
        "Naitasiri","Namosi","Ra","Rewa","Serua","Tailevu"],
      fullNameError: {
        status: null,
        message: "",
      },
      dateofbirthError: {
        status: null,
        message: "",
      },
      addressNumError: {
        status: null,
        message: "",
      },
      addressStreetError: {
        status: null,
        message: "",
      },
      addressCityError: {
        status: null,
        message: "",
      },
      addressZipError: {
        status: null,
        message: "",
      },
      addressProvinceError: {
        status: null,
        message: "",
      },
      addressCountryError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    itemContent(val) {
      console.log("item content watch", val);
      this.fullName = val.name ? val.name : "";
      this.dateofbirth = val.birthdate ? val.birthdate : "";
      this.addressNum = val.address ? val.address.number : "";
      this.addressStreet = val.address ? val.address.street : "";
      this.addressCity = val.address ? val.address.city : "";
      this.addressZip = val.address ? val.address.zipcode : "";
      this.addressProvince = val.address ? val.address.province : "";
      this.addressCountry = val.address ? val.address.country : "";
    },
    fullName(value) {
      if (value) {
        this.fullNameError.status = true;
      } else {
        this.fullNameError.status = false;
        this.fullNameError.message = "Please fill in the Full Name";
      }
    },
    dateofbirth(value) {
      if (value) {
        this.dateofbirthError.status = true;
      } else {
        this.dateofbirthError.status = false;
        this.dateofbirthError.message = "Please Select the Date of Birth";
      }
    },
    addressNum(value) {
      if (value) {
        this.addressNumError.status = true;
      } else {
        this.addressNumError.status = false;
        this.addressNumError.message = "Please fill in the address";
      }
    },
    addressStreet(value) {
      if (value) {
        this.addressStreetError.status = true;
      } else {
        this.addressStreetError.status = false;
        this.addressStreetError.message = "Please fill in the Street";
      }
    },
    addressCity(value) {
      if (value) {
        this.addressCityError.status = true;
      } else {
        this.addressCityError.status = false;
        this.addressCityError.message = "Please fill in the City";
      }
    },
    addressZip(value) {
      if (value) {
        this.addressZipError.status = true;
      } else {
        this.addressZipError.status = false;
        this.addressZipError.message = "Please fill in the Zip code";
      }
    },
    addressProvince(value) {
      if (value) {
        this.addressProvinceError.status = true;
      } else {
        this.addressProvinceError.status = false;
        this.addressProvinceError.message = "Please fill in the Province";
      }
    },
    addressCountry(value) {
      if (value) {
        this.addressCountryError.status = true;
      } else {
        this.addressCountryError.status = false;
        this.addressCountryError.message = "Please fill in the Country";
      }
    },
  },
  async mounted() {
    await this.initFn();
    this.isEditProduct = this.$route.meta.isEdit;
    if (this.isEditProduct) {
      this.initEditProductFn(this.$route.params.productId);
      // console.log(this.$route.params.invoiceId);
    }
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
    filteredProvinceList() {
      if (this.addressCountry === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (this.addressCountry === "Australia") {
        return this.AusiProvinceList;
      } else if (this.addressCountry === "Fiji") {
        return this.fijiProvinceList;
      } else {
        return this.NewzelandProvinceList;
      }
    },
  },
  methods: {
    async initFn() {
      console.log("item content user", this.itemContent);
      // this.fullName = this.itemContent.name;
    },
    async updateProfileFn() {
      if (
        this.fullName &&
        this.dateofbirth &&
        this.addressNum &&
        this.addressStreet &&
        this.addressCity &&
        this.addressZip &&
        this.addressProvince &&
        this.addressCountry
      ) {
        let payload = {
          address: {
            number: this.addressNum,
            street: this.addressStreet,
            city: this.addressCity,
            zipcode: this.addressZip,
            province: this.addressProvince,
            country: this.addressCountry,
          },
          birthdate: this.dateofbirth,
        };
        try {
          let { data, status } = await UpdateUserProfile(payload);
          console.log("update profile", data);
          if (status == 200) {
            {
              let payloadNotify = {
                isToast: true,
                title: "Profile Update",
                content: "The profile has been updated successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              console.log("notify", payloadNotify);
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.errorFn();
      }
    },
    errorFn() {
      if (!this.fullName) {
        this.fullNameError.status = false;
        this.fullNameError.message = "Please fill in the Full Name";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please enter the Full Name",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.dateofbirth) {
        this.dateofbirthError.status = false;
        this.dateofbirthError.message = "Please Select the Date of Birth";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please Select the Date of Birth",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressNum) {
        this.addressNumError.status = false;
        this.addressNumError.message = "Please fill in the Address";
        let payloadNotify = {
          isToast: true,
          title: "Error:  Unable to Update profile Details",
          content: "Please enter the Address",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressStreet) {
        this.addressStreetError.status = false;
        this.addressStreetError.message = "Please fill in the Street";
        let payloadNotify = {
          isToast: true,
          title: "Error:  Unable to Update profile Details",
          content: "Please enter the the Street",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressCity) {
        this.addressCityError.status = false;
        this.addressCityError.message = "Please fill in the City";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please enter the City",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressZip) {
        this.addressZipError.status = false;
        this.addressZipError.message = "Please fill in the Zip Code";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please enter the the Zip Code",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressProvince) {
        this.addressProvinceError.status = false;
        this.addressProvinceError.message = "Please fill in the province";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please enter the province",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (!this.addressCountry) {
        this.addressCountryError.status = false;
        this.addressCountryError.message = "Please fill in the country";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to Update profile Details",
          content: "Please enter the country",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
